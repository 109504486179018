/* Calendar */
.my-calendar {
  background-color: var(--score-card-theme-table-body-background-color);
  color: var(--score-card-theme-table-body-color);
}

.rbc-toolbar button {
  background-color: var(--score-card-theme-button-background-color);
  color: var(--score-card-theme-button-color);
}

.rbc-day-bg {
  background-color: var(--score-card-theme-table-header-background-color);
  color: var(--score-card-theme-table-header-color);
}

.rbc-off-range-bg {
  background-color: var(--score-card-theme-table-body-background-color);
  color: var(--score-card-theme-table-body-color);
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: var(--score-card-theme-button-hover-background-color);
  color: var(--score-card-theme-button-hover-color);
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-time-content {
  border-top: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-header + .rbc-header {
  border-top: 1px solid var(--score-card-theme-table-border-color);
  border-left: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-header,
.rbc-time-header {
  border-bottom: 1px solid var(--score-card-theme-table-border-color);
  background-color: var(--score-card-theme-table-header-background-color);
  color: var(--score-card-theme-table-header-color);
}

.rbc-month-view,
.rbc-time-view {
  border: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-timeslot-group {
  border-bottom: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-time-header-content {
  border-left: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-time-content > * + * > * {
  border-left: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-today {
  background-color: rgba(255, 0, 0, 0.2);
  color: var(--score-card-theme-table-total-color);
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-bottom: 1px solid var(--score-card-theme-table-border-color);
}

.rbc-toolbar button {
  border: 1px solid var(--score-card-theme-table-border-color);
}

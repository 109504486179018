/* theme colors */

/* light theme */
.theme-light {
  --score-card-theme-text-color: black;
  --score-card-theme-background-color: rgb(248, 248, 248);
  --score-card-theme-hover: 0, 0, 0;
  --score-card-theme-topbar-background-color: white;
  --score-card-theme-topbar-text-color: black;
  --score-card-theme-card-color: black;
  --score-card-theme-card-background-color: white;
  --score-card-theme-card-title-background-color: rgb(248, 248, 248);
  --score-card-theme-link-color: #07689f;
  --score-card-theme-link-color-hover: rgba(#07689f, 0.5);
  --score-card-theme-input-color: black;
  --score-card-theme-input-background-color: rgb(240, 240, 240);
  --score-card-theme-nav-link: black;
  --score-card-theme-border-color: lightgray;
  --score-card-theme-button-color: black;
  --score-card-theme-button-color-primary: white;
  --score-card-theme-button-background-color: #f7f7f7;
  --score-card-theme-button-white-color: var(--score-card-theme-text-color);
  --score-card-theme-button-white-background-color: var(--score-card-theme-background-color);
  --score-card-theme-button-primary-color: black;
  --score-card-theme-button-primary-background-color: #e0e0e0;
  --score-card-theme-button-hover-background-color: #e0e0e0;
  --score-card-theme-button-hover-color: black;
  --score-card-theme-table-header-background-color: rgb(240, 240, 240);
  --score-card-theme-table-header-color: black;
  --score-card-theme-table-body-background-color: transparent;
  --score-card-theme-table-body-color: black;
  --score-card-theme-table-border-color: lightgray;
  --score-card-theme-table-total-background-color: var(--score-card-theme-background-color);
  --score-card-theme-table-total-color: white;
  --score-card-theme-table-inout-background-color: rgb(240, 240, 247);
  --score-card-theme-table-inout-color: black;
  --score-card-theme-list-item-background-color: transparent;
  --score-card-theme-list-item-color: black;
  --score-card-theme-list-item-selected-background-color: rgb(192, 192, 192);
  --score-card-theme-list-item-selected-color: black;
  --score-card-theme-hover-background-color: rgb(208, 208, 208);
  --score-card-theme-hover-color: black;
  background-color: var(--score-card-theme-background-color);
  color: var(--score-card-theme-text-color);
}

/* dark theme */
.theme-dark {
  --score-card-theme-text-color: rgb(220, 220, 220);
  --score-card-theme-background-color: rgb(64, 64, 64);
  --score-card-theme-hover: 0, 0, 0;
  --score-card-theme-topbar-background-color: white;
  --score-card-theme-topbar-text-color: var(--score-card-theme-text-color);
  --score-card-theme-card-selected-background-color: rgb(96, 96, 96);
  --score-card-theme-card-background-color: rgb(56, 56, 56);
  --score-card-theme-card-title-background-color: rgb(64, 64, 64);
  --score-card-theme-card-color: var(--score-card-theme-text-color);
  --score-card-theme-link-color: #07689f;
  --score-card-theme-link-color-hover: rgba(#07689f, 0.5);
  --score-card-theme-input-background-color: rgb(48, 48, 48);
  --score-card-theme-input-color: var(--score-card-theme-text-color);
  --score-card-theme-nav-link: var(--score-card-theme-text-color);
  --score-card-theme-border-color: rgb(127, 127, 127);
  --score-card-theme-button-white-color: black;
  --score-card-theme-button-white-background-color: white;
  --score-card-theme-button-color: var(--score-card-theme-text-color);
  --score-card-theme-button-color-primary: white;
  --score-card-theme-button-background-color: transparent;
  --score-card-theme-button-primary-color: var(--score-card-theme-text-color);
  --score-card-theme-button-primary-background-color: #e0e0e0;
  --score-card-theme-button-hover-background-color: #e0e0e0;
  --score-card-theme-button-hover-color: black;
  --score-card-theme-table-header-background-color: rgb(80, 80, 80);
  --score-card-theme-table-header-color: white;
  --score-card-theme-table-body-background-color: transparent;
  --score-card-theme-table-body-color: var(--score-card-theme-text-color);
  --score-card-theme-table-border-color: rgb(32, 32, 32);
  --score-card-theme-table-total-background-color: var(--score-card-theme-background-color);
  --score-card-theme-table-total-color: white;
  --score-card-theme-table-inout-background-color: rgb(48, 48, 48);
  --score-card-theme-table-inout-color: white;
  --score-card-theme-list-item-background-color: transparent;
  --score-card-theme-list-item-color: white;
  --score-card-theme-list-item-selected-background-color: rgb(96, 96, 96);
  --score-card-theme-list-item-selected-color: white;
  --score-card-theme-hover-background-color: rgb(72, 72, 72);
  --score-card-theme-hover-color: white;
  background-color: var(--score-card-theme-background-color);
  color: var(--score-card-theme-text-color);
}

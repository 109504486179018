@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Roboto Condensed:400,500,600,700,800,900");

@import "index-themes.css";
@import "index-rbc.css";

:root {
  --score-card-grid-cell-size: 42px;
  --score-double-eagle: 4px;
  --score-double-border: 4px;
  --score-width: 48px;
  --score-height: 48px;
}

html,
body {
  margin: 0;
  font-family: "Roboto", "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  border-bottom-width: 1px;
  border-color: var(--score-card-theme-table-border-color);
  color: var(--score-card-theme-table-body-color);
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--score-card-theme-table-border-color);
  border-bottom-width: 2px;
}

table {
  font-size: 14px;
  color: var(--score-card-theme-table-body-color) !important;
}

table thead tr,
table thead th,
table tbody tr.title-row {
  padding: 8px 8px;
}

table thead th {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--score-card-theme-table-header-background-color);
  color: var(--score-card-theme-table-header-color);
}

tr:hover {
  cursor: pointer;
}

.nav-tabs {
  border-bottom: 1px solid var(--score-card-theme-border-color);
}

.navigate-hover {
  color: var(--score-card-theme-link-color);
  font-weight: 500;
}

.navigate-hover:hover {
  cursor: pointer;
  opacity: 0.75;
}

table tbody td.total-column {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--score-card-theme-table-inout-background-color);
}

.table {
  margin-bottom: unset;
}

td {
  vertical-align: middle;
  text-align: center;
}

.page-title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Roboto Condensed";
}

.page-title-counter {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto Condensed";
}

.text-theme {
  color: var(--score-card-theme-text-color);
}

.card {
  border: unset;
  background-color: var(--score-card-theme-card-background-color);
  color: var(--score-card-theme-card-color);
  border-radius: 16px;
}

@media only screen and (max-width: 600px) {
  .card {
    border-radius: 0px;
    background-color: var(--score-card-theme-card-background-color);
    color: var(--score-card-theme-card-color);
    /*    border-radius: 16px; */
  }
}

.list-group-item {
  background-color: var(
    --score-card-theme-list-item-unselected-background-color
  );
  color: var(--score-card-theme-list-item-unselected-color);
  padding: 4px 8px 8px 8px;
  border-radius: 12px;
}

.list-group-item-selected {
  background-color: var(--score-card-theme-list-item-selected-background-color);
  color: var(--score-card-theme-list-item-selected-color);
  padding: 4px 8px 8px 8px;
  border-radius: 12px;
}

.list-group-item:hover {
  background-color: var(--score-card-theme-hover-background-color);
  color: var(--score-card-theme-hover-color);
}

.btn {
  font-family: "Roboto Condensed";
  text-transform: uppercase;
  font-weight: 400;
  color: var(--score-card-theme-button-color);
}

.btn-white-bg {
  font-family: "Roboto Condensed";
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--score-card-theme-button-white-background-color);
  color: var(--score-card-theme-button-white-color);
}

.btn-white-bg-rounded {
  font-family: "Roboto Condensed";
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--score-card-theme-button-white-background-color);
  color: var(--score-card-theme-button-white-color);
  width: 48px;
  height: 48px;
  border-radius: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset;
}

.btn:hover {
  background-color: var(--score-card-theme-button-hover-background-color);
  color: var(--score-card-theme-button-hover-color);
}

.btn-group {
  border-color: unset;
}

.btn-outline-primary {
  border-color: var(--score-card-theme-border-color);
}

.btn-primary {
  font-family: "Roboto Condensed";
  text-transform: uppercase;
  font-weight: 400;
  color: var(--score-card-theme-button-color-primary);
}

.btn-light {
  background-color: unset;
  border-color: transparent;
}

.btn-light:disabled {
  color: var(--score-card-theme-button-color);
  background-color: var(--score-card-theme-button-background-color);
  border: unset;
}

.btn-roboto {
  font-family: "Roboto";
  text-transform: uppercase;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  border-color: unset;
  opacity: 0.25;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  color: var(--score-card-theme-button-color);
}

.nav-tabs .nav-link {
  color: var(--score-card-theme-button-color);
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-weight: 600;
}

.nav-tabs .nav-link:hover {
  opacity: 0.5;
}

.data-label {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.data-content {
  font-size: 28px;
  text-align: center;
}

.score-card-info-label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto Condensed";
  text-transform: uppercase;
}

.score-card-info-data {
  font-size: 28px;
  font-weight: 400;
  font-family: "Roboto Condensed";
}

.score-card-info-symbol {
  font-size: 18px;
  font-family: "Roboto Condensed";
}

.score-card-info-separator {
  border-right: 1px solid var(--score-card-theme-border-color);
  height: 64px;
  width: 1px;
}

.avatar-img {
  border-radius: 50%;
  border: 1px solid rgb(64, 64, 64);
  /*  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5); */
}

.form-control,
.form-select {
  background-color: var(--score-card-theme-input-background-color);
  color: var(--score-card-theme-input-color);
  border: unset;
}

.form-label {
  background-color: transparent;
  color: var(--score-card-theme-input-color);
  border: unset;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 600;
  margin-left: 12px;
}

a {
  color: var(--score-card-theme-text-color);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--score-card-theme-input-background-color);
  color: var(--score-card-theme-input-color);
  opacity: 1;
}

.input-group-text {
  background-color: var(--score-card-theme-input-background-color);
  color: var(--score-card-theme-input-color);
  border: unset;
}

.navbar-dark .navbar-toggler {
  color: transparent;
  border-color: transparent;
}

.text-danger {
  color: red !important;
}

.modal-content {
  background-color: var(--score-card-theme-card-background-color);
  color: var(--score-card-theme-card-color);
}

.modal-content .modal-header {
  background-color: var(--score-card-theme-card-title-background-color);
  color: var(--score-card-theme-card-title-color);
  border-bottom: unset;;
}

.modal-content .modal-header .btn-close {
  color: var(--score-card-theme-text-color);
}

.modal-content .modal-body {
  background-color: var(--score-card-theme-card-background-color);
  color: var(--score-card-theme-card-color);
  padding: 0px !important;
}  

.dropdown-menu {
  background-color: var(--score-card-theme-card-background-color);
  color: var(--score-card-theme-card-color);
}

.dropdown-item {
  color: var(--score-card-theme-card-color);
}

@import "index-score-legend.css";

.header-navbar {
  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 18px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  font-weight: 600;

  color: var(--score-card-theme-text-color);
}

.header-navbar .navbar-toggle-icon {
  color: var(--score-card-theme-text-color);
}

.header-navbar .header-brand {
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  font-weight: 800;
  letter-spacing: -1px;
  color: var(--score-card-theme-text-color);
}

.header-navbar .header-version {
  font-size: 14px;
  font-family: 'Roboto Condensed';
  font-weight: 400;
  letter-spacing: -1px;
  color: var(--score-card-theme-text-color);
}

.header-navbar .nav-link {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  color: var(--score-card-theme-text-color);
}

.navbar-light .navbar-nav .nav-link:hover {
  opacity: 0.6;
}
